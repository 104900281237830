import React from 'react'
import Helmet from 'react-helmet'
import config from '../../utils/siteConfig'
import Layout from '../../components/Layout'
import Container from '../../components/Container'
import PageTitle from '../../components/PageTitle'
import SEO from '../../components/SEO'

const UserAgreement = ({ data }) => {
  const postNode = {
    title: `UserAgreement - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`UserAgreement - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="UserAgreement" customTitle />

        <Container>
        <PageTitle>User Agreement</PageTitle>

    <h1>PLEASE CAREFULLY READ THESE TERMS OF USE BEFORE YOU USE THIS SERVICE.</h1>

<h2>1. WHAT SERVICES DO THESE TERMS COVER?</h2>
<p>These terms of use (“Terms”) cover the following products and services (“Services”), however accessed and/or used or made available by Snoopo GmbH or any of its affiliates (“Snoopo” or “we”, “us” or “our”):</p>
<ul>
  <li>all apps published by Snoopo, whether for tablets, smart TVs, video game consoles, social media platforms, mobile devices or other platforms (“Apps”) unless indicated otherwise in the app;</li>
  <li>all other interactive features, services and communication provided by Snoopo.</li>
</ul>

<h2>2. THIS IS AN AGREEMENT BETWEEN YOU UND SNOOPO GMBH.</h2>

<p>By accessing, downloading or using these Services, you acknowledge that you have read, understood and agree to these Terms as well as to any code of conduct as we may issue from time to time. If you allow minors to use the Services, you also agree that such minors will also be bound by these Terms. Snoopo’s Privacy Policy describes the collection, use, transfer and disclosure of the personal data that you have made available to us or will be collected by us. By agreeing to comply with these Terms, you also confirm that you have read, understood and given your consent to the collection, use, transfer and disclosure of personal information or data regarding you as described in the Privacy Policy (as most recently amended). Please review the Privacy Policy before you use the Service.</p>

<p><strong>In the event you do not accept these Terms, you may neither access nor use the Service. We may update and revise the Terms from time to time. By continuing to use the Service, you acknowledge your acceptance of such modifications.</strong></p>

<p><strong>We reserve the right to suspend or terminate your access to any current or future use of our Service without notice for failure to comply with the Terms or if we find, at our sole discretion, that suspension or termination is in the best interest of a fair, positive and safe environment for the users of the Service.</strong></p>

<h2>3. USE OF THE SERVICES</h2>

<h3>3.1 Content of Offering</h3>

<p>This App enables users to actively search for digital boxes that contain either money and/or the in-game currency, “Snoopo Coins”, and/or vouchers from different sponsors. Boxes are found by using an overall that shows boxes that are nearby. When the user approaches a box, the distance displayed will also become smaller until a specific radius is reached, which may lie between 50 meters and 5 kilometers. Once within this radius, the user must come within five meters of the box to activate the camera in which the box is then displayed and can be clicked open.</p>

<p>Users can also compare their performance with that of friends or with national and international rankings and progress to a higher level by opening the boxes. When a user reaches a higher level, he or she receives rewards (“Snoopo Coins”, multipliers, hints) that can be used in the game.</p>

<p>The money found in the boxes is credited to the user’s player account and can be withdrawn by the user by providing his or her PayPal data. Withdrawal is possible only once the amount specified in the App is reached and must be made through PayPal. The money found is denominated in US dollars. Users are shown the equivalent amount in their respective national currencies at the prevailing daily exchange rate. When a withdrawal is made, disbursement is made in the national currency of the user at the prevailing daily exchange rate.</p>

<p>Users may use the “Snoopo Coins” found in the boxes to purchase power-ups, which may take the form of multipliers for the content of a box or better hints on boxes, or make purchases in the in-game shop. “Snoopo Coins” will not be redeemed in cash</p>

<p>The vouchers from the various sponsors found in the boxes will be subject to the general terms and conditions of the respective sponsors. Users must contact the respective sponsor directly to redeem a voucher. Snoopo will not establish contact with sponsors or forward users’ data to sponsors.</p>

<h3>3.2 Terms for Participation</h3>

<p>Natural persons of the age of 18 and above may use the Services after registration and acceptance of the Terms of Use. By registering, users affirm that they are of legal age. Use by minors is prohibited.</p>

<p>It is necessary to register and open an account to use the Services. Users must have access to a support cell phone and an internet connection. By opening an account, the user consents to the use of their GPS by the App to determine the location of the user, display nearby boxes and monitor compliance with the Terms of Use (see section 3.4). The user further consents to the use of his or her mobile data volume for the purposes of ongoing updating.</p>

<p>It is necessary to provide a user name, a password and an e-mail address to register. Users must inform Snoopo of any changes in their e-mail address without delay.</p>

<p>You agree to refrain from disclosing your access data, and in particular your password, to third parties and to inform Snoopo without delay of any unauthorized use of your account. You will be responsible for all activities that take place through your account. You understand that Snoopo will not be held responsible for any loss or damage resulting from unauthorized use of your Snoopo account.</p>

<p>You may search for boxes <strong>exclusively on foot</strong>. The use of bicycles, automobiles or any other vehicles is prohibited.</p>

<p>A <strong>PayPal account is required</strong> for disbursement of money found in the boxes. Other forms of disbursement are not possible.</p>

<h3>3.3 Secure and Appropriate Use</h3>

<p>Please pay attention to your environment when using the Services and exercise caution at all times. You use the Services at your own risk. You agree to refrain from using the Services in violation of applicable laws and regulations or these Terms of Use. You agree to comply with all applicable laws and regulations, including but not limited to such laws, rules and regulations as may pertain to copyright, trademark or other intellectual property rights, slander and libel, defamation, loss of business, commercial disparagement, harassment, violation of privacy, tortious conduct, obscenity, immoral conduct and infringement of copyright or trademark. Violation of applicable law may result in civil and/or criminal investigation or penalties.</p>

<p>These Terms authorize you to use the content of the Services for your own personal, non-commercial home use to the extent of your personal ability. You may not under any circumstances</p>

<ol>
  <li>alter or remove the copyright or any other proprietary notice from the content of the Services;</li>
  <li>create or modify derivative works based on the content;</li>
  <li>use or facilitate the use of unauthorized third-party software (e.g., bots, mods, hacks and scripts) to modify the Service in any way or automate the operation thereof for your own use or for the use of others;</li>
  <li>use the content in a manner that (a) implies an association with other products, services or brands, (b) is likely to cause confusion among customers under certain circumstances, (c) disparages or discredits Snoopo, (d) that is otherwise exploitative for any commercial purpose or (e) or otherwise infringes our intellectual property rights;</li>
  <li>download any content to a database for whatsoever reason;</li>
  <li>decompile, disassemble or reverse engineer any part of the Services or otherwise use a Service for any purpose other than that for which it was made available by us and in conjunction with the operation of the Service;</li>
  <li>develop products or services that are functionally similar to any of the Services;</li>
  <li>use a Service for the benefit of any third party or transfer access to the Service to a third party;</li>
  <li>use the Service or content for commercial purposes, including but not limited to (a) the sale of any right to access the Service in its entirety or in part or (b) the placement of advertising, sponsorships or promotions on or within the Services or the content;</li>
  <li>bypass, circumvent or disable any digital rights management system or features of a Service;</li>
  <li>infiltrate any system used to operate the Services;</li>
  <li>vtake any action to cause or exploit any known or latent malfunctions, bugs or other defects of a Service or system used to operate the Service; or</li>
  <li>attempt to circumvent restrictions based on age or place of residence or any other restriction that we have incorporated into a Service.</li>
</ol>

<h3>3.4 Fraud</h3>

<p>Snoopo does not condone fraud and makes every effort to implement and improve measures to combat fraud. Fraud is any action taken in an effort to modify, influence or circumvent the normal functionality or rules of the Services. The definition of fraud covers but is not limited to the following actions (taken for one’s own benefit or for that of others):</p>

<ol>
  <li>unauthorized access to services (including the use of modified or unofficial software of third-party providers);</li>
  <li>the use of several accounts for the same Service;</li>
  <li>sharing of accounts;</li>
  <li>use of any methods to change or disguise the location of a device (e.g., through GPS spoofing);</li>
  <li>use of a bicycle, automobile or other vehicle to search for boxes (see section 3.2)</li>
</ol>

<p>Apps may not function on devices that Snoopo suspects or has ascertained are being used for fraudulent purposes. Fraudulently obtained money or Snoop Coins will not be disbursed or will become invalid. The user consents to the use of legal mechanisms by Snoopo, including but not limited to the GPS of the user, to detect and respond to fraud, deception or other actions that are prohibited under these Terms of Use.</p>

<p>Notwithstanding any provisions to the contrary as may be contained herein, we may at any time suspend or terminate your access to any or all of the Services without prior notice and without prior notice and refuse to allow you to use our Services at the present time or in the future.</p>

<h2>4. INTELLECTUAL PROPERTY, SERVICE CONTENT UND SUBMISSIONS.</h2>

<p>Unless stipulated otherwise, all services, including articles, artwork, screen shots, graphics, logos, downloads and other files are the property of Snoopo and protected under German and international copyright and trademark law as well as under other laws governing intellectual property. Trademarks and copyrights of third-party games and characters are the property of the companies that market or license such products. You represent and warrant, to the maximum extent permitted by law, that you have no rights of ownership or other rights in the content of the Services, including – but not limited to – content that you have created or developed yourself, including screen names, game scores, chat content and other messages submitted to a Service or directly to us.</p>

<p>All communication, solicited feedback and other materials submitted to the Service (by e-mail or by other means) will be treated as non-confidential and non-proprietary. By submitting materials to the Service, you waive, to the maximum extent permitted by law, all claims arising in connection with any infringement of your rights through the use of such materials, including but not limited to moral rights, rights of ownership, publicity rights, rights to credit for materials or ideas or any other rights, including the right to require that such materials be used only with your prior consent. You further grant us and all successors a perpetual, royalty-free worldwide license to use, transmit, copy and display such submitted information in any media now known or hereinafter devised and represent and warrant that you are in possession of all rights in such postings. No further consideration or compensation will be paid for any materials or information (including but not limited to materials or information of a creative, financial, business and commercial nature, etc.) submitted in any manner. It is also important for the success of various of our Services that any errors or problems encountered in connection with Snoopo be confidentially reported to Snoopo Customer Service so that we can address such issues as quickly as possible. Our contact particulars are provided at the end of these Terms of Use.</p>

<p>Opinions, suggestions and any other information expressed by any third parties on the Service represent the views of such third parties and not those of Snoopo. You should not rely on such opinions, suggestions or other information. Neither Snoopo nor any of its affiliates or their respective agents, directors, employees, information providers, licensors, licensees, officers and/or affiliates will be responsible or liable for decisions made on the basis of such information.</p>

<h2>5. COPYRIGHT INFRINGEMENT CLAIMS</h2>

<p>Please contact us if you are of the opinion that any part of this Service infringes your copyright and you would like to notify Snoopo of such infringement. We will address the matter as quickly as possible. Our contact particulars are provided at the end of these Terms of Use.</p>

<h2>6. GRANT OF RIGHTS</h2>

<p>Subject to compliance with the Terms of Use, Snoopo grants you a limited, non-exclusive, non-transferable, non-sublicensable license to download and install a copy of the App on a mobile device and execute such a copy of the App exclusively for your own personal, non-commercial purposes. You also receive a personal, non-commercial, non-exclusive, non-transferable, non-sublicensable, revocable limited license to download, display, reproduce and use the content (copy, software, scripts, graphics, photos, sound, music, etc.) exclusively for your personal authorized use within the Services.</p>

<h3>Snoopo Coins:</h3>

<p>Snoopo Coins are virtual currency and a form of content. You can access and acquire virtual currency for your personal, non-commercial use of the Services. You acknowledge that you acquire no right of ownership in the virtual currency. A credit balance denominated in virtual currency does not correspond to a stored value and you agree that virtual currency has no monetary value and does not represent a physical currency or property of any nature whatsoever. You may not transfer virtual currency to another party or account. Any such transfer (or attempted transfer) is prohibited, will constitute a breach of these Terms of Use and may result in cancellation of the virtual currency or closure of your account.</p>

<p>Snoopo reserves the right to offer, modify, extinguish and/or terminate virtual currency without notifying you in advance or assuming any liability. In the event we terminate the use of virtual currency, we will notify you accordingly at least 60 days in advance by issuing notification through the Services or another form of communication.</p>

<h3>Right to Use Content Created and Shared by You:</h3>

<p>You will retain the rights of ownership in content you create and share through Snoopo and nothing contained in these Terms of Use will deprive you of such rights. However, you must give us various legal permissions to use such content in order for us to be able to make our Services available.</p>

<p>In particular if you post or upload content that is protected by intellectual property rights (such as photos or videos) to or in connection with our products, you grant us a non-exclusive, assignable, sublicensable and worldwide license to host, use, disseminate, modify, execute, copy, publicly perform or display, translate and make derivative works of such content.</p>

<p>You may terminate any such license at any time by deleting the corresponding content.</p>

<h3>Use of Your Name, Profile and Information on Interaction with Advertising and Sponsored Content:</h3>

<p>Your name and profile as well as information on your activities in connection with Snoopo may be used without payment of consideration along with or in connection with advertisements, offers and other sponsored content that we display in our products. Please consult our Privacy Policy for further information.</p>

<h3>Right to Update Software that You Use or Download:</h3>

<p>If you download or use our software, you grant us the right to download and install upgrades, updates and additional features to improve, enhance or further develop the software.</p>

<p>We reserve the right to suspend or terminate your access to or any current or future use of our Service without notice due to refusal to comply with the Terms or if we find, at our sole discretion, that suspension or termination is in the best interest of a fair, positive and secure environment for users of the Service.</p>

<h2>7. THIRD-PARTY PRODUCTS AND SERVICES.</h2>

<p>The Service may contain links to third-party websites, services and platforms that do not belong to and are not controlled by us. We are not responsible for content on websites operated by third parties, including content on sites operated by distributors who link to a Service with the consent of Snoopo. We are also not responsible for maintaining any materials referenced from other sites. We make no warranties for such sites or this Service in such contexts. Access to the Service may also require prior registration of certain information, including game ID number, third-party account information (such as iTunes or Google Play account information), or the use of other personal data to use certain features of the Service. We are not responsible for any damage or failure of hardware or software or certain services, including damage to game data or points, etc. Please consult our Privacy Policy for further information.</p>

<h2>8. UPDATES UND CHANGES IN THE SERVICE.</h2>

<p>We may from time to time issue patches or updates for portions of the Services that you must install to be able to continue to use those portions of the Services. You agree to accept and install such patches and updates to continue to use the Services. You also acknowledge that the Services, or portions thereof, may be exposed to server issues that can result in temporary unavailability and that we may, at our sole discretion, terminate the Services or any part thereof without prior notice. You acknowledge and agree that you will have no legal recourse of any kind against us if this causes you to lose a match, lose virtual content or no longer have access to the entire Service or any part thereof.</p>

<h2>9. DISCLAIMER.</h2>

<p>INSOFAR AS PERMISSIBLE UNDER APPLICABLE LAW, THIS SERVICE AND ALL OF ITS CONTENT ARE PROVIDED WITH NO WARRANTY AGAINST POSSIBLE DEFECTS. WE PROVIDE NO WARRANTY OF ANY NATURE WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, UNINTERRUPTED USE OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR NEEDS OR BE AVAILABLE WITHOUT INTERRUPTION, SECURE OR ERROR-FREE. WE MAKE NO WARRANTY IN RESPECT OF THE QUALITY, ACCURACY, TIMELINESS, CORRECTNESS, COMPLETENESS OR RELIABILITY OF CONTENT.</p>

<h2>10. LIMITATION OF LIABILITY</h2>

<p>BY USING THE SERVICE, YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICE IS AT YOUR OWN RISK, THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED WITH ALL SERVICING OR REPAIRS OF ANY EQUIPMENT YOU USE IN CONNECTION WITH OUR SERVICES AND THAT NEITHER SNOOPO NOR ANY OF ITS AFFILIATES OR THEIR RESPECTIVE AGENTS, DIRECTORS, EMPLOYEES, INFORMATION PROVIDERS, LICENSORS, LICENSEES, OFFICERS AND/OR AFFILIATES WILL BE LIABLE FOR DAMAGE OF ANY NATURE WHATSOEVER IN CONNECTION WITH THE USE OF THIS SERVICE.</p>

<p>INSOFAR AS PERMISSIBLE UNDER APPLICABLE LAW, NEITHER SNOOPO NOR ANY OF ITS AFFILIATES OR THEIR RESPECTIVE AGENTS, DIRECTORS, EMPLOYEES, INFORMATION PROVIDERS, LICENSORS, LICENSEES, OFFICERS AND/OR AFFILIATES WILL BE LIABLE FOR CONSEQUENTIAL DAMAGES (SUCH AS, FOR EXAMPLE, LOSS OF PROFIT, LOSS OF DATA, COMPUTER DAMAGE), DIRECT, INCIDENTAL, INDIRECT OR SPECIAL DAMAGES OR PUNITIVE DAMAGES IN CONNECTION WITH (A) THE USE, (B) THE INABILITY TO USE OR (C) ERRORS OR OMISSIONS IN THE CONTENT AND FUNCTIONS OF THIS SERVICE. THIS WILL ALSO APPLY ACCORDINGLY IN THE CASE OF ANY WARRANTY, AGREEMENT OR TORT AND EVEN IF SNOOPO OR AN AUTHORIZED AGENT OR SNOOPO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

<p>SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CONSEQUENTIAL OR INCIDENTAL DAMAGES AND THE ABOVE LIMITATION OR EXCLUSION MAY THEREFORE NOT APPLY TO YOU. INSOFAR AS LEGALLY PERMISSIBLE, THE TOTAL LIABILITY OF SNOOPO AND ITS AFFILIATES OR THEIR RESPECTIVE AGENTS, DIRECTORS, EMPLOYEES, INFORMATION PROVIDERS, LICENSORS, LICENSEES, OFFICERS AND/OR AFFILIATES FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT) WILL UNDER NO CIRCUMSTANCES EXCEED € 1000.00.</p>

<p>THIS LIMITATION OF LIABILITY WILL NOT APPLY IN THE CASE OF WILLFUL MISCONDUCT, GROSS NEGLIGENCE OR ILLNESS, PERSONAL INJURY OR LOSS OF LIFE. LIABILITY UNDER THE GERMAN PRODUCT LIABILITY ACT (PRODUKTHAFTUNGSGESETZ – PRODHAFTG) WILL REMAIN UNAFFECTED IN RESPECT OF RESIDENTS OF GERMANY.</p>

<p>IN THE CASE OF LOSS OR DAMAGE SUFFERED BY RESIDENTS OF GERMANY DUE TO SLIGHT NEGLIGENCE, SNOOPO WILL BE LIABLE ONLY INSOFAR AS SUCH LOSS OR DAMAGE IS CAUSED BY A BREACH OF A MATERIAL CONTRACTUAL OBLIGATION AND SUCH LIABILITY WILL THEN BE LIMITED TO THE AMOUNT OF TYPICAL LOSS OR DAMAGE AS WOULD HAVE BEEN REASONABLY FORESEEABLE IN THE CASE OF SUCH CONTRACTUAL RELATIONSHIPS UPON EXECUTION OF THE AGREEMENT. MATERIAL CONTRACTUAL OBLIGATIONS ARE SUCH OBLIGATIONS AS MUST BE FULFILLED TO PERMIT PROPER PERFORMANCE OF THE CONTRACT AND UPON WHICH THE RESPECTIVE OTHER PARTY MAY REGULARLY RELY.</p>

<p>YOU ACKNOWLEDGE AND AGREE THAT SNOOPO CANNOT OFFER YOU THE SERVICES WITHOUT THE FOREGOING EXCLUSIONS AND LIMITATIONS OF LIABILITY AND RECOURSE. WE WOULD AND COULD OTHERWISE NOT MAKE THE SERVICE AVAILABLE TO YOU. You acknowledge and agree that we may take any action that we consider appropriate if we should discover that you have failed to comply with any part of the provisions, including but not limited to closure of your accounts and termination of access to the Services. You acknowledge that you will in any such case not receive any reimbursement or other compensation for any items associated with your account at the time of termination of access.</p>

<h2>11. INDEMNIFICATION.</h2>

<p>You agree to indemnify, defend and hold Snoopo, its affiliates, agents, directors, employees, information providers, licensors, licensees and/or officers (collectively the “Indemnified Parties”) harmless from and against any costs or expenses (including, without limitation, attorneys' fees) as may be incurred by the Indemnified Parties in connection with any legal claim arising from any breach by you of the Terms of Use or the foregoing representations, warranties and covenants. You agree to cooperate in the defense of any such claim. Snoopo reserves the right to assume, at its own expense, the exclusive defense and control of any matter otherwise subject to indemnification by you. You may not enter into any settlement regarding any such matter without the written consent of Snoopo.</p>

<h2>12. GENERAL PROVISIONS</h2>

<p>These Terms of Use, our Privacy Policy and any additional terms posted in this Service constitute the entire agreement between Snoopo and you regarding the use of the Services.</p>

<p>In the event any of the provisions contained in these Terms of Use should prove invalid or unenforceable, the validity of the remaining provisions will not be affected.</p>

<h2>13. FOR RESIDENTS OF GERMANY: INFORMATION PURSUANT TO § 36 OF THE ACT ON ALTERNATIVE DISPUTE RESOLUTION IN CONSUMER MATTERS (GESETZ ÜBER DIE ALTERNATIVE STREITBEILEGUNG IN VERBRAUCHERSACHEN – VSBG):</h2>

<p>We are neither willing nor under any obligation to participate in dispute resolution proceedings before a consumer conciliation body.</p>

<h2>14. CONTACT</h2>

<p>If you have queries or questions regarding these Terms of Use, please contact Snoopo GmbH by e-mail at <a href="info@snoopo.com">info@snoopo.comsnoopo</a> or by postal mail at Berlin Allee 34 – 36, 40212 Düsseldorf.</p>
        </Container>
    </Layout>
  )
}

export default UserAgreement
